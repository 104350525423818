import { useGlobalStore } from "@/stores/global"

export default defineNuxtRouteMiddleware((to, from) => {
  const runtimeConfig = useRuntimeConfig()
  const store = useGlobalStore()
  const envAppEnvironment = runtimeConfig.public.appEnvironment

  // // Redirect new user to /intro if they try to access / or /manage-profiles.
  // if (to.path === "/" || to.path === "/manage-profiles") {
  //   if (store.newUser && envAppEnvironment !== "ci") {
  //     return navigateTo("/intro")
  //   }
  // }
  // Redirect legacy paths.
  if (to.path === "/intro") {
    return navigateTo("/")
  }

  // Redirect legacy paths.
  switch (to.path) {
    case "/known-issues":
      return navigateTo("/feedback")
    case "/many-vs-one":
      return navigateTo("/")
    case "/simulate-attack":
      return navigateTo("/")
  }

  // Guard /dev/** paths if env is not dev.
  if (!import.meta.dev && to.path.startsWith("/dev/")) {
    return abortNavigation()
  }
})
