import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as changelogcHu9mc7iuuMeta } from "/opt/build/repo/pages/changelog.vue?macro=true";
import { default as migrate_45preset_45modifiersSlrqbNAYkMMeta } from "/opt/build/repo/pages/dev/migrate-preset-modifiers.vue?macro=true";
import { default as migrate_45preset_45profilesZWmYgWl3HDMeta } from "/opt/build/repo/pages/dev/migrate-preset-profiles.vue?macro=true";
import { default as modifier_45sandboxZYOHGYtgK5Meta } from "/opt/build/repo/pages/dev/modifier-sandbox.vue?macro=true";
import { default as faqKBZt61hnJ8Meta } from "/opt/build/repo/pages/faq.vue?macro=true";
import { default as feedbackzoUTey8ci5Meta } from "/opt/build/repo/pages/feedback.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as introaivcM3w7lwMeta } from "/opt/build/repo/pages/intro.vue?macro=true";
import { default as manage_45profilesOsemOsWt1gMeta } from "/opt/build/repo/pages/manage-profiles.vue?macro=true";
import { default as mathhammerMERFHhyogTMeta } from "/opt/build/repo/pages/mathhammer.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as send_45debug_45datazU39umJo7UMeta } from "/opt/build/repo/pages/send-debug-data.vue?macro=true";
import { default as support_45unitcrunchVWzHnWK4vKMeta } from "/opt/build/repo/pages/support-unitcrunch.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogcHu9mc7iuuMeta || {},
    component: () => import("/opt/build/repo/pages/changelog.vue")
  },
  {
    name: "dev-migrate-preset-modifiers",
    path: "/dev/migrate-preset-modifiers",
    meta: migrate_45preset_45modifiersSlrqbNAYkMMeta || {},
    component: () => import("/opt/build/repo/pages/dev/migrate-preset-modifiers.vue")
  },
  {
    name: "dev-migrate-preset-profiles",
    path: "/dev/migrate-preset-profiles",
    meta: migrate_45preset_45profilesZWmYgWl3HDMeta || {},
    component: () => import("/opt/build/repo/pages/dev/migrate-preset-profiles.vue")
  },
  {
    name: "dev-modifier-sandbox",
    path: "/dev/modifier-sandbox",
    component: () => import("/opt/build/repo/pages/dev/modifier-sandbox.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqKBZt61hnJ8Meta || {},
    component: () => import("/opt/build/repo/pages/faq.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    meta: feedbackzoUTey8ci5Meta || {},
    component: () => import("/opt/build/repo/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "intro",
    path: "/intro",
    component: () => import("/opt/build/repo/pages/intro.vue")
  },
  {
    name: "manage-profiles",
    path: "/manage-profiles",
    component: () => import("/opt/build/repo/pages/manage-profiles.vue")
  },
  {
    name: "mathhammer",
    path: "/mathhammer",
    meta: mathhammerMERFHhyogTMeta || {},
    component: () => import("/opt/build/repo/pages/mathhammer.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy.vue")
  },
  {
    name: "send-debug-data",
    path: "/send-debug-data",
    meta: send_45debug_45datazU39umJo7UMeta || {},
    component: () => import("/opt/build/repo/pages/send-debug-data.vue")
  },
  {
    name: "support-unitcrunch",
    path: "/support-unitcrunch",
    meta: support_45unitcrunchVWzHnWK4vKMeta || {},
    component: () => import("/opt/build/repo/pages/support-unitcrunch.vue")
  }
]